import React, { Component, useState, useEffect, useContext, useReducer, useRef } from "react";
import { connect } from "react-redux";


//#region import components
//<atom-region>
import EditProfileMenu from '../form/EditProfileMenu.js'

import { login, logout } from '../../hooks/store/actions/loginActions.js';
import { searchContacts } from '../../hooks/store/actions/contactActions.js';
import { showEditForm } from '../../hooks/store/actions/contactActions.js';
import { showNavbar, hideNavbar } from '../../hooks/store/actions/navBarActions';
//</atom-region>
//#endregion import components

//#region import material-ui components
//<atom-region>
import { fade, makeStyles } from '@material-ui/core/styles';
;
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import { green } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row'; 
import aplogo from '../app/style/AC_PrimaryCenteredLogo.png';
import toaster from 'toasted-notes';
import { startEditProfile, selectUser } from '../../hooks/store/actions/loginActions';
import MediaQuery from 'react-responsive';
import Button from 'react-bootstrap/Button';
import { serverEnvironment } from "../../hooks/store/actionConsts";
import AffiliationProgressBar from './affiliation/AffiliationProgressBar';

//</atom-region>
//#endregion import material-ui components

let showTests = 0;
let showConsoleLog = 0;

//const MaterialUIAppBar = () => {
const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        float: 'right',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

const MenuSearchBar = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [clientSearchBox, setclientSearchBox] = React.useState(null);
    const [windowLocation, setWindowLocation] = React.useState(null);

    useEffect(() => {
        setWindowLocation(document.location.pathname);
    }, []);




    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = event => {
        if (!anchorEl) {
            setAnchorEl(event.currentTarget);
        }
        else
            setAnchorEl(null);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = event => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const onClickEditProfile = (e) => {
        props.startEditProfile();
    }

    const onClickUnimpersonate = event => {
        props.selectUser(props.sysUser, true); 
    }

    const menuId = 'primary-search-account-menu';

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}

            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >

            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle className="itsthewrongone" />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    const onClickDashboard = event => {
        document.location = "/dashboard";
    };
    const onClickNewContact = event => {
        document.location = "/newContact";
    };
    const onClickViewContact = event => {
        if (document.location.toString().includes("/viewContacts")) {
            props.hideNavbar();
            props.showEditForm(null);
        }
        else {
            document.location = "/viewContacts";
        }
    };
    const onClickClient = event => {
        document.location = "/clientMgmt";
    };
    const onClickAction = event => {
        document.location = "/action";
    };
    const onClickReports = event => {
        document.location = "/reports";
    };
    const onClickSettings = event => {
        document.location = "/settings";
    };
    const onClickAffiliation = event => {
        document.location = "/affiliation";
    };
    const onClickLibrary = event => {
        document.location = "/library";
    };
    const logout = event => {
        props.logout(props.token);
    }

    const Notification = ({ message, color }) => {
        return <div className="popup-notification" style={{ color: color }}>{message}</div>;
    };

    const search = event => {
        if (document.location.href.includes("newContact")) {
            toaster.notify("Please navigate to another tab to search clients");
            return;
        }
        if (clientSearchBox.length < 2) {
            toaster.notify("Need at least 2 letters to Search...", { duration: 4000 });
            return;
        }
        else {
            props.searchContacts(props.token, props.user.id, clientSearchBox);
        }
    }


    const enterPressed = event => {
        var code = event.keyCode || event.which;
        if (code === 13) {
            search(event);
        }
    }

    const enableButtonTranslate = () => {
        console.log('hasFFStripeConnect', props.newDataProfile);
        const { featureFlags } = props.newDataProfile;

        if (!featureFlags || featureFlags === "") 
            return false;

        const features = featureFlags.split(",");
        console.log('User features:', features);


        return features.includes("enable_button_translate");
    }
    return (
        <>
            <div>
                <div className="searchBar">

                    <div className="searchBarLeft">
                        <MediaQuery minWidth={850}>
                            <span className="acLogo-top">
                                <img className="acLogoImg" src={aplogo} alt="AC Logo" />
                            </span>
                        </MediaQuery>
                        <MediaQuery maxWidth={849}>
                            {props.sysUser.logoURL && props.sysUser.logoURL ?
                                (<span className="acLogo-top">
                                    <img className="acLogoImg mobileNavLogo" src={props.sysUser.logoURL} alt="AC Logo" />
                                </span>
                                )
                                :
                                (<span className="acLogo-top">
                                    <img className="acLogoImg" src={aplogo} alt="AC Logo" />
                                </span>
                                )
                            }
                        </MediaQuery>
                        <img className="spacerLine" />

                        {props.sysUser && (
                            <span className="clientLogo hide-below-850">
                                {props.sysUser.logoURL ? (
                                    <img className="clientLogoImg" src={props.sysUser ? props.sysUser.logoURL : ""} alt="Affiliate Logo" />
                                )
                                    :
                                    (
                                        <label onClick={onClickEditProfile} className="addLogoButtonNav " htmlFor="logoURLfile">Add Logo</label>
                                    )}
                            </span>
                        )}
                        <img className="spacerLine hide-below-850" />

                        <span className="userGreeting hide-below-850">
                            <tr className="userGreeting-top">welcome</tr>
                            <tr className="userGreeting-bottom notranslate"><div className="sysUserName">{props.sysUser.firstName} {props.sysUser.lastName}</div></tr>
                        </span>
                        
                        <span className="userGreeting-top"><AffiliationProgressBar /></span>

                        <img className="spacerLine hide-below-850" />

                        <span>
                            <div style={{ marginBottom: '-23px', marginTop: '7px', marginLeft: '3px' }}>
                            <span className="logout-button" onClick={logout}>
                                &nbsp;&nbsp;logout
                                <div style={{ display: (serverEnvironment.API_URL.includes('acadminmt-dev') || serverEnvironment.API_URL.includes('acadminmt-sandbox') ? 'block' : 'none'), marginLeft: '-5px', marginTop: '-60px', color: '#fb084b', fontStyle: 'italic' }} className="sysUserName">
                                    {(serverEnvironment.API_URL.includes('acadminmt-dev') ? 'DEVELOPMENT' : '')}
                                    {(serverEnvironment.API_URL.includes('acadminmt-sandbox') ? 'SANDBOX' : '')} SITE 
                                </div>
                                </span>
                            </div>
                            <span className="hide-below-850 mb5 mt15">
                                {!serverEnvironment.API_URL.includes('acadminmt-dev') && !serverEnvironment.API_URL.includes('acadminmt-sandbox')
                                    ? <><br />
                                        <div><a href="mailto:Help@accomplishmentcoaching.com?subject=[Support]%20Request%20for%20Support%20from%20a%20Hub%20user">
                                            <span style={{ color: 'darkblue', fontWeight: '600', fontSize: '14px', lineHeight: '0.1' }}>Have Questions? Need Support?</span></a></div>
                                        <div style={{marginTop: '-9px' }}><a href="mailto:Help@accomplishmentcoaching.com?subject=[Support]%20Request%20for%20Support%20from%20a%20Hub%20user">
                                            <span style={{ color: 'darkblue', fontWeight: '600', fontSize: '14px', lineHeight: '0.1'}}><u>Help@accomplishmentcoaching.com</u></span></a></div>
                                    </>
                                    : <></>
                                }
                            </span>
                        </span>
                        
                        <div>
                            
                            <div className="hide-below-850">
                                {((props.sysUser && props.sysUser.userRole == 3) || (props.sysUser && props.sysUser.userRole == 8) || (props.sysUser && props.sysUser.userRole == 37)) && (
                                <>
                                    {props.sysUser.id !== props.user.id ? (
                                        <>

                                            <div className="adminModeText"><span style={{ color: "red" }}>[Admin Mode] </span> Impersonating <span><b>{props.user.firstName} </b>
                                                <b>{props.user.lastName}</b></span></div>
                                        </>
                                    )
                                        :
                                        (
                                            <>
                                                    <div className="adminModeText"><span style={{ color: "red" }}>[Admin Mode] </span> <label className="translateResizeFont">No Current Impersonation</label></div>
                                            </>
                                            )}
                                </>
                            )}
                        </div>
                        {props.sysUser.id !== props.user.id && (
                  
                        <div className="hide-below-850">
                            <div className="unimpersonateContainerNav"><span className="unimpersonateButton" onClick={onClickUnimpersonate}><span>&#10006;</span>Unimpersonate</span></div>
                        </div>
                            )}
                        </div>
                    
                    </div>    
                    <div className="searchBoxContainer">

                        <input className="clientSearchBox" id="searchInputFocus" placeholder={window.innerWidth > 500 ? "Contact Search" : "Search"} onKeyPress={enterPressed} value={clientSearchBox} onChange={(e) => setclientSearchBox(e.target.value)} />
                        <span className="searchBox">
                            <FontAwesomeIcon onClick={search} icon="search" transform="grow-1" />
                        </span>
                    </div>
                    <div style={{ display: enableButtonTranslate() ? 'default' : 'none' }}> 
                        <div id="googleTranslate"> </div>
                    </div>
                </div>
                <div className="searchHeader" position="static">
                    <Toolbar>
                        <div id="divDashboard" className={(windowLocation == '/dashboard' || windowLocation == '/') ? "topNav navFirst navSelect" : "topNav navFirst"} onClick={onClickDashboard}>
                            <div>
                                <FontAwesomeIcon icon="eye" /> <span className="hide-below-850">Dashboard</span>
                            </div>
                            <div className="hide-above-850" style={{height: '2px'}}><span className="tooltiptext">Dashboard</span></div>
                        </div>
                        <div id="divNewContact" className={windowLocation == '/newContact' ? "topNav navSelect" : "topNav"} onClick={onClickNewContact}>
                            <div>
                                <FontAwesomeIcon icon="plus" /> <span className="hide-below-850">New Contact</span>
                                <div className="hide-above-850" style={{ height: '2px' }}><span className="tooltiptext">New Contact</span></div>
                            </div>
                        </div>
                        <div id="divViewContacts" className={windowLocation == '/viewContacts' ? "topNav navSelect" : "topNav"} onClick={onClickViewContact}>
                            <div>
                                <FontAwesomeIcon icon="user" /> <span className="hide-below-850">View/Edit Contacts</span>
                                <div className="hide-above-850" style={{ height: '2px' }}><span className="tooltiptext">View/Edit Contacts</span></div>
                            </div>
                        </div>
                        <div id="divClientMgt" className={windowLocation == '/clientMgmt' ? "topNav navSelect" : "topNav"} onClick={onClickClient}>
                            <div>
                                <FontAwesomeIcon icon="users" /> <span className="hide-below-850">Client Management</span>
                                <div className="hide-above-850" style={{ height: '2px' }}><span className="tooltiptext">Client Mgt</span></div>
                            </div>
                        </div>
                        <div id="divActions" className={windowLocation == '/action' ? "topNav navSelect" : "topNav"} onClick={onClickAction}>
                            <div>
                                <FontAwesomeIcon icon="running" /> <span className="hide-below-850">Take an Action&nbsp;</span>
                                <div className="hide-above-850" style={{ height: '2px' }}><span className="tooltiptext">Take an Action</span></div>
                            </div>
                        </div>
                        <div id="divReports" className={windowLocation == '/reports' ? "topNav navSelect" : "topNav"} onClick={onClickReports}>
                            <div>
                                <FontAwesomeIcon transform={'left-1'} icon="file-alt" /> <span className="hide-below-850">Reports</span>
                                <div className="hide-above-850" style={{ height: '2px' }}><span className="tooltiptext">Reports</span></div>
                            </div>
                        </div>
                        <div id="divAffiliation" className={windowLocation == '/affiliation' ? "topNav navSelect" : "topNav"} onClick={onClickAffiliation}>
                            <div>
                                <FontAwesomeIcon transform={'left-1'} icon="dollar-sign" /> <span className="hide-below-850">Affiliate</span>
                                <div className="hide-above-850" style={{ height: '2px' }}><span className="tooltiptext">Affiliate</span></div>
                            </div>
                        </div>
                        {props.sysUser.userRole > 2 ? (
                            <div id="divSettings" className={windowLocation == '/settings' ? "topNav navSelect" : "topNav"} onClick={onClickSettings}>
                                <div className="hide-above-850" style={{ height: '2px' }}><span className="tooltiptext">Settings</span></div>
                                <div>
                                    <FontAwesomeIcon icon="cog" /> <span className="hide-below-850">Settings</span>
                                </div>
                            </div>
                        ) : <></>}
                        {(props.sysUser && props.sysUser.canAccessDocs) ? (
                            <div id="divAffiliation" className={windowLocation == '/library' ? "topNav navSelect" : "topNav"} onClick={onClickLibrary}>
                                <div>
                                    <FontAwesomeIcon transform={'left-1'} icon="book" /> <span className="hide-below-850">Library</span>
                                    <div className="hide-above-850" style={{ height: '2px' }}><span className="tooltiptext">Library</span></div>
                                </div>
                            </div>
                        ) : <></>}

                        <div className={classes.grow} />
                        <div className={classes.sectionDesktop}>

                            <IconButton
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={onClickEditProfile}
                                color="inherit"
                            >
                                <FontAwesomeIcon className="itstherightone" transform="shrink-3" icon="user-circle" />&nbsp;
                                <span className="menuSearchBarTopNavRight">
                                    Profile
                                </span>
                            </IconButton>
                            &nbsp;
                            <IconButton
                                edge="end"
                                aria-label="support"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                color="white"
                            >
                                <a style={{ textDecoration: 'none' }} href="mailto:Help@accomplishmentcoaching.com?subject=[Support]%20Request%20for%20Support%20from%20a%20Hub%20user">
                                    <FontAwesomeIcon transform="shrink-3" icon="question" color="white"/>&nbsp;
                                    <span className="menuSearchBarTopNavRight">
                                        Help
                                    </span>
                                </a>
                            </IconButton>
                        </div>
                        <div className={classes.sectionMobile}>
                            <IconButton
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={onClickEditProfile}
                                color="inherit"
                            >
                                <FontAwesomeIcon className="itstherightone" transform="shrink-3" icon="user-circle" title="Profile" />&nbsp;
                            </IconButton>
                            <IconButton
                                edge="end"
                                aria-label="support"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                color="white"
                            >
                                <a href="mailto:Help@accomplishmentcoaching.com?subject=[Support]%20Request%20for%20Support%20from%20a%20Hub%20user">
                                    <FontAwesomeIcon transform="shrink-3" icon="question" color="white" title="Help" />&nbsp;
                                </a>
                                {/* <span className="menuSearchBarTopNavRight">
                                    Help
                                </span> */}
                            </IconButton>
                            {/* <IconButton
                                aria-label="show more"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={handleMobileMenuOpen}
                                color="inherit"
                            >
                                <MoreIcon />
                            </IconButton> */}
                        </div>
                    </Toolbar>
                </div>
                {anchorEl && (
                    <EditProfileMenu anchorEl={anchorEl} />
                )}{renderMobileMenu}
            </div>

        </>
    );
}
//}

function mapStateToProps(state) {
    return {
        token: state.loginReducer.loginToken,
        message: state.loginReducer.message,
        sysUser: state.loginReducer.sysUser,
        user: state.loginReducer.user,
        userContact: state.contactReducer.userContact,
        newDataProfile: state.loginReducer.newDataProfile
    }
}

export default connect(mapStateToProps, { login, logout, searchContacts, startEditProfile, hideNavbar, selectUser, showEditForm })(MenuSearchBar);
