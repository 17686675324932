import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Label from 'react-bootstrap/FormLabel';
import BillingPlanDetailRow from './BillingPlanDetailRow.js';
import { serverEnvironment, sleep, monthNames } from '../../../hooks/store/actionConsts';
import { getEmptyInvoice, deactivateBillingPlan, activateBillingPlan } from '../../../hooks/store/actions/financeActions.js';
import MediaQuery from 'react-responsive';
import Modal from 'react-bootstrap/Modal';
import ExtendBillingPlan from './ExtendBillingPlan';
import PauseBillingPlan from './PauseBillingPlan';

class BillingPlanDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            createInv: false,
            showBillingPlan: false,
            showPauseModal: false,
        };

        this.deactivateBP = this.deactivateBP.bind(this);
        this.pauseAgreement = this.pauseAgreement.bind(this);
        this.onClickHideBillingPlan = this.onClickHideBillingPlan.bind(this);
        this.onClickHidePauseModal = this.onClickHidePauseModal.bind(this);

    }

    formatDate(d) {
        if (d && !d.length) {
            var temp = String(d.getFullYear()) + "-";
            if (String(d.getMonth()+1).length < 2) {
                temp = temp + "0";
            }
            temp = temp + String(d.getMonth()+1) + "-"
            if (String(d.getDate()).length < 2) {
                temp = temp + "0";
            }
            temp = temp + String(d.getDate());
            d = temp;
        }
        if (d && d.length >= 10) {
            var m = Number(d.substring(5, 7));
            if (parseInt(d.substring(0, 4)) >= '2099') {
                return 'Ongoing'
            }
            else {
                return monthNames[m - 1] + " " + d.substring(8, 10) + ", " + d.substring(0, 4);
            }
        }
        else
            return "";
    }

    getStatus(id) {
        var ret = this.props.invoiceStatus.filter((a) => id === a.id);
        if (ret && ret.length > 0) {
            return ret[0].name;
        }
    }

    onClickHideBillingPlan = () => {
        this.setState({ showBillingPlan: false });
    }
    onClickHidePauseModal = () => {
        this.setState({ showPauseModal: false });
    }

    getPaymentMethod(id) {
        if (id === 5) { return 'Pro-Bono' }
        else {
            var ret = this.props.paymentMethods.filter((a) => Number(id) === a.id);
            if (ret && ret.length > 0) {
                return ret[0].name;
            }
        }
    }

    showBillingPlanModal = () => {
        this.setState({showBillingPlan: true})
    }

    closeBillingPlanModal = (newBp) => {
        if (newBp) this.props.save(newBp)
        this.setState({ showBillingPlan: false })
    }
    closePauseModal = () => {
        this.setState({ showPauseModal: false })
        // this.props.closeModal();
    }

    deactivateBP(month, bp, sendEmail) {
        bp.pauseUntilDate = month;
        if (sendEmail) {
            if (!this.props.emptyInvoice.invoiceTotal) {
                toaster.notify("Please enter a positive invoice amount", { duration: 4000 });
                return;
            }
            bp.statusID = 3;
            bp.notes = this.formatNotes();
            this.props.requestManualInvoice(this.props.logintoken, bp);
        } else {
            this.props.deactivateBillingPlan(this.props.logintoken, bp);
        }
        this.setState({ showPauseModal: false, showBillingPlan: false });
        sleep(500).then(() => {
            this.refreshClientDetails()
        })
    }

    pauseAgreement(bp) {
        this.setState({ showPauseModal: true });

        //alert('here')
        if (bp && bp.statusID === 3) {
            //alert('here2')
            this.props.activateBillingPlan(this.props.logintoken, bp);
            sleep(500).then(() => {
                this.forceUpdate();
            })
        }
        else {
            //alert('here3')
            this.setState({ showBillingPlan: false, showInvoiceForm: false });
        }
    }

    refreshClientDetails() {
        document.location = "/clientMgmt/autoload";
    }

    render() {

        return (<div className="billingplan-detail-form">
            <Row>
                <Col sm={2}><Label className="details-label"><b>CALCULATION TYPE</b></Label></Col><Col><Label className="details-value notranslate">{this.props.bp.calculationType}</Label></Col>
                <Col sm={2}><Label className="details-label"><b>SESSION LENGTH</b></Label></Col><Col><Label className="details-value notranslate">{this.props.bp.sessionLength}</Label></Col>
                <Col sm={2}><Label className="details-label"><b>ACCEPTED ON</b></Label></Col><Col><Label className="details-value notranslate">{this.formatDate(this.props.bp.acceptedOnUTC)}</Label></Col>
            </Row><Row>
                <Col sm={2}><Label className="details-label"><b>PAYMENT METHOD</b></Label></Col><Col><Label className="details-value notranslate">{this.getPaymentMethod(this.props.bp.paymentMethodId)}</Label></Col>
                <Col sm={2}><Label className="details-label"><b>CREATION TYPE</b></Label></Col><Col><Label className="details-value notranslate">{this.props.bp.creationType}</Label></Col>
                <Col sm={2}><Label className="details-label"><b>FIRST DAY OF SERVICE</b></Label></Col><Col><Label className="details-value notranslate">{this.formatDate(this.props.bp.firstDayOfService)}</Label></Col>
            </Row><Row>
                <Col sm={2}><Label className="details-label"><b>LAST DAY OF SERVICE</b></Label></Col><Col><Label className="details-value notranslate">{this.formatDate(this.props.bp.lastDayOfService)}</Label></Col>
                <Col sm={2}><Label className="details-label"><b>CUSTOMER SERVICE AGREEMENT</b></Label></Col><Col>
                    {this.props.bp.CSAFileName && (
                        <a target="_blank" title="Customer Service Agreement" style={{ fontSize: 'small' }} rel="noopener noreferrer" href={"https://acadminhubstorage.blob.core.windows.net/billingplandocuments/" + String(this.props.bp.CSAFileName)}>CSA</a>
                    )}
                </Col>
                {this.props.sysUser.userRole !== 8 && (
                    <><Col sm={2}>&nbsp;
                    </Col><Col>&nbsp;</Col></>
                        )}
                {this.props.sysUser.userRole === 8 && (
                    <><Col sm={2}>
                        <Label className="details-label"><b>CREDIT CARD AUTHORIZATION</b></Label></Col><Col>
                            {this.props.bp.CCAFileName && (
                                <a target="_blank" title="Credit Card Authorization" style={{ fontSize: 'small' }} rel="noopener noreferrer" href={"https://acadminhubstorage.blob.core.windows.net/billingplandocuments/" + String(this.props.bp.CCAFileName)}>CCA</a>
                            )}
                    </Col></>
                )}
            </Row>
            <Row></Row>
            <span>
                <Row><Col><span className="details-label-noborder"><b>BILLING PLAN DETAILS</b></span></Col></Row>
                <MediaQuery minWidth={576}>
                    <Row className="grid-row row-header billingPlanDetailHeader">
                        <Col className="billingPlanDetailTitle" sm={1}>TYPE</Col>
                        <Col className="billingPlanDetailTitle" sm={1}>TOTAL</Col>
                        <Col className="billingPlanDetailTitle" sm={1}>PRE-TAX TOTAL</Col>
                        <Col className="billingPlanDetailTitle" sm={1}>AFFILIATE RATE</Col>
                        <Col className="billingPlanDetailTitle" sm={1}>BILLING FEE</Col>
                        <Col className="billingPlanDetailTitle" sm={1}>MERCHANT FEE</Col>
                        <Col className="billingPlanDetailTitle" sm={1}>AFFILIATE TAX</Col>
                        <Col className="billingPlanDetailTitle" sm={1}>NET PAYMENT</Col>
                        <Col className="billingPlanDetailTitle" sm={1}>NET INCOME</Col>
                        <Col className="billingPlanDetailTitle" sm={1}>NET BEFORE TAX</Col>
                        <Col className="billingPlanDetailTitle" sm={1}>TOTAL TAX</Col>
                    </Row>
                </MediaQuery>
                <MediaQuery maxWidth={575}>
                    <Col className="billingPlanDetailTitleSmall" sm={1}>TYPE</Col>
                    <Col className="billingPlanDetailTitleSmall" sm={1}>TOTAL</Col>
                    <Col className="billingPlanDetailTitleSmall" sm={1}>PRE-TAX TOTAL</Col>
                    <Col className="billingPlanDetailTitleSmall" sm={1}>AFFILIATE RATE</Col>
                    <Col className="billingPlanDetailTitleSmall" sm={1}>BILLING FEE</Col>
                    <Col className="billingPlanDetailTitleSmall" sm={1}>MERCHANT FEE</Col>
                    <Col className="billingPlanDetailTitleSmall" sm={1}>AFFILIATE TAX</Col>
                    <Col className="billingPlanDetailTitleSmall" sm={1}>NET PAYMENT</Col>
                    <Col className="billingPlanDetailTitleSmall" sm={1}>NET INCOME</Col>
                    <Col className="billingPlanDetailTitleSmall" sm={1}>NET BEFORE TAX</Col>
                    <Col className="billingPlanDetailTitleSmall" sm={1}>TOTAL TAX</Col>
                </MediaQuery>
                {this.props.bp.billingPlanDetails && (
                    <BillingPlanDetailRow details={this.props.bp.billingPlanDetails} />
                )}
                <Row className={this.props.row % 2 === 0 ? "grid-row row-white grid-billing-plan billingPlanRowButtons" : "grid-row row-gray grid-billing-plan billingPlanRowButtons"} >
                    <Col md={5}>
                        {this.props.bp.statusID === 1 && (
                            <Row>
                                <Col className="buttonControlsBillingRow noPaddingButton"><Button className="button-gridrow clientDetailsBtn" onClick={() => (this.props.cancelBP(this.props.bp))} href="#">TERMINATE BILLING PLAN</Button></Col>
                            </Row>
                        )}
                        {this.props.bp.statusID === 2 && (
                            <Row>
                                <Col className="buttonControlsBillingRow noPaddingButton"><Button className="button-gridrow clientDetailsBtn" onClick={() => (this.pauseAgreement(this.props.bp))} href="#">PAUSE BILLING PLAN</Button></Col>
                                <Col className="buttonControlsBillingRow noPaddingButton"><Button className="button-gridrow clientDetailsBtn" onClick={() => (this.props.cancelBP(this.props.bp))} href="#">TERMINATE BILLING PLAN</Button></Col>
                                {!this.props.editing && (
                                    <Col className="buttonControlsBillingRow noPaddingButton"><Button style={{ display: this.props.bp.paymentMethodId === 5 ? 'none' : 'block' }} className="button-gridrow clientDetailsBtn" onClick={() => this.showBillingPlanModal()} href="#">EXTEND BILLING PLAN</Button></Col>
                                )}
                                {this.props.editing && (
                                    <Col className="buttonControlsBillingRow noPaddingButton"><Button className="button-gridrow clientDetailsBtn" onClick={() => (this.props.cancelEdit(this.props.bp))} href="#">CANCEL EDIT</Button></Col>
                                )}
                                {this.props.editing && (
                                    <Col className="buttonControlsBillingRow noPaddingButton"><Button className="button-gridrow clientDetailsBtn" onClick={() => (this.props.save(this.props.bp))} href="#">SAVE BILLING PLAN</Button></Col>
                                )}
                            </Row>
                        )}
                        {this.props.bp.statusID === 3 && (
                            <Col className="buttonControlsBillingRow noPaddingButton" sm={2}><Button className="button-gridrow clientDetailsBtn" onClick={() => (this.props.activateBP(this.props.bp))} href="#">UNPAUSE</Button></Col>
                        )}
                    </Col>

                </Row>
            </span>
            {this.props.logintoken && this.state.showBillingPlan && (
                    <Modal
                        {...this.props}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className="modal-popup pauseBillingPlanModalContainer"
                        show={this.state.showBillingPlan}
                        onHide={this.onClickHideBillingPlan}
                    >                        
                        <ExtendBillingPlan 
                            showInvoiceForm={this.state.showInvoiceForm} 
                            invoice={this.props.emptyInvoice}
                            currentContact={this.props.currentContact && this.props.currentContact.contactID}
                            bp={this.props.bp} 
                            terminateBP={this.terminateAgreement}
                            show={true} 
                            hideForm={this.onClickHideBillingPlan} 
                            closeBillingPlanModal={this.closeBillingPlanModal}
                            />                        
                    </Modal>
            )}


            {this.props.logintoken && this.state.showPauseModal && (
                <Modal
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="modal-popupPauseRequest pauseBillingPlanModalContainerv2"
                    show={this.state.showPauseModal}
                    onHide={this.onClickHidePauseModal}
                    style={{width: '80%', marginLeft: '10%', marginTop: '10%', marginRight: '10%', padding: '20px'}}
                >
                    <PauseBillingPlan
                        showInvoiceForm={this.state.showInvoiceForm}
                        invoice={this.props.emptyInvoice}
                        currentContact={this.props.currentContact && this.props.currentContact.contactID}
                        bp={this.props.bp}
                        deactivateBP={this.deactivateBP}
                        terminateBP={this.terminateAgreement}
                        show={true}
                        hideForm={this.onClickHidePauseModal}
                        closeBillingPlanModal={this.closePauseModal}
                    />
                </Modal>

            )}

            
        </div>
        );

    }

}

function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        sysUser: state.loginReducer.sysUser,
        paymentMethods: state.sysReducer.paymentMethods,
        invoiceStatus: state.sysReducer.invoiceStatus,
        invoiceTypes: state.sysReducer.invoiceTypes
    }
}

export default connect(mapStateToProps, { getEmptyInvoice, activateBillingPlan, deactivateBillingPlan })(BillingPlanDetails);
